import React from 'react'

export default function About({aboutData}) {
    function NewlineText(paragraph) {
        const text = paragraph;
        const newText = text.split("\n").map((str) => (
          <>
            {str}
            <br />
          </>
        ));
    
        return newText;
      }
    return (
        <div className="container">
        <div className="hero-container " style={{paddingTop: 10, marginTop: 10}}>
          <div className="hero-text-container">
            <h1 className="noe-font-title hero-h1" style={{fontFamily: "PTSerifBold", letterSpacing: 1, color: "#66585b"}}>
              {aboutData?.header ? aboutData?.header?.toUpperCase() : "ABOUT"}
            </h1>
            <h3 className="hero-h3">
              {aboutData?.paragraph ? (
                NewlineText(aboutData?.paragraph)
              ) : (
                <p>
LaLou is a Prospect Heights restaurant and wine bar with a focus on natural, biodynamic and small-production wines. LaLou was named one of the “20 Best Wine Bars in the US” by Tasting Table and “One of the Best Wine Bars in NYC” by Eater and The Infatuation. The 250+ bottle list is a collaboration between friends and former colleagues Joe Campanale (restaurateur, beverage expert, Food & Wine Sommelier of the Year) and Dave Foss (a hospitality veteran and former Anfora wine buyer).   
                </p>
              )}
  
            </h3>
          </div> 

        </div>
        {aboutData?.image1 && (
        <div className="flex-row-desktop">
        <img alt={aboutData?.image1?.alt || "Wine Glasses and Plants"} loading="lazy" className="about-img" effect="blur" src={aboutData?.image1.url} />
        <img alt={aboutData?.image2?.alt || "Wine Glasses and Plants"} loading="lazy" className="about-img" effect="blur" src={aboutData?.image2.url} />
        <img alt={aboutData?.image3?.alt || "Wine Glasses and Plants"} loading="lazy" className="about-img" effect="blur" src={aboutData?.image3.url} />
        <img alt={aboutData?.image4?.alt || "Wine Glasses and Plants"} loading="lazy" className="about-img" effect="blur" src={aboutData?.image4.url} />
        </div>
        )}

      </div>
    )
}