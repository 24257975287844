import React from "react";
import CheckoutCart from "../Icons/CheckoutCart";
import lalouLogo from "../../Assets/Css/imgs/LALOU-logo-30in.jpg"

const Header = ({ setOpenCart, openCart, showDialog, closeDialog, showBadge }) => {
  return (
    <div className="header-container">
      <div className="lalou-logo">
        <img src={lalouLogo} />
      </div>

      <div className={`icon-wrapper `}>
 
        <div
          className="cart_icon"
          style={{ width: "40px", cursor: "pointer" }}
          onClick={() => {
            setOpenCart(!openCart);
          }}
        >
          {showBadge ? <div className="bag-badge"></div> : ""}
          <CheckoutCart />
        </div>
      </div>
    </div>
  );
};

export default Header;
