import React from 'react';

export default function CheckoutCart() {
	return (
			<svg  viewBox="0 0 40 20">
				<path
					d="M21.5317 14.1362H6.99316L7.72314 12.6494L19.852 12.6274C20.2622 12.6274 20.6138 12.3345 20.687 11.9292L22.3667 2.52734C22.4106 2.28076 22.3447 2.02686 22.1836 1.83398C22.104 1.73906 22.0046 1.6626 21.8925 1.6099C21.7803 1.55721 21.6581 1.52954 21.5342 1.52881L6.10449 1.47754L5.97266 0.857422C5.88965 0.461914 5.5332 0.173828 5.12793 0.173828H1.35596C1.12739 0.173828 0.908183 0.264626 0.746561 0.426248C0.584939 0.58787 0.494141 0.807077 0.494141 1.03564C0.494141 1.26421 0.584939 1.48342 0.746561 1.64504C0.908183 1.80666 1.12739 1.89746 1.35596 1.89746H4.42969L5.00586 4.63672L6.42432 11.5044L4.59814 14.4854C4.50331 14.6134 4.44619 14.7653 4.43324 14.9241C4.4203 15.0829 4.45205 15.2421 4.5249 15.3838C4.67139 15.6743 4.9668 15.8574 5.29394 15.8574H6.82715C6.50029 16.2916 6.32374 16.8203 6.32422 17.3638C6.32422 18.7456 7.44727 19.8687 8.8291 19.8687C10.2109 19.8687 11.334 18.7456 11.334 17.3638C11.334 16.8193 11.1533 16.2896 10.8311 15.8574H14.7642C14.4373 16.2916 14.2607 16.8203 14.2612 17.3638C14.2612 18.7456 15.3843 19.8687 16.7661 19.8687C18.1479 19.8687 19.271 18.7456 19.271 17.3638C19.271 16.8193 19.0903 16.2896 18.7681 15.8574H21.5342C22.0078 15.8574 22.396 15.4717 22.396 14.9956C22.3946 14.7673 22.3029 14.5488 22.141 14.3878C21.9791 14.2268 21.7601 14.1364 21.5317 14.1362ZM6.46338 3.17676L20.521 3.22314L19.144 10.9331L8.10156 10.9526L6.46338 3.17676ZM8.8291 18.1353C8.4043 18.1353 8.05762 17.7886 8.05762 17.3638C8.05762 16.939 8.4043 16.5923 8.8291 16.5923C9.25391 16.5923 9.60058 16.939 9.60058 17.3638C9.60058 17.5684 9.5193 17.7646 9.37462 17.9093C9.22994 18.054 9.03371 18.1353 8.8291 18.1353ZM16.7661 18.1353C16.3413 18.1353 15.9946 17.7886 15.9946 17.3638C15.9946 16.939 16.3413 16.5923 16.7661 16.5923C17.1909 16.5923 17.5376 16.939 17.5376 17.3638C17.5376 17.5684 17.4563 17.7646 17.3116 17.9093C17.167 18.054 16.9707 18.1353 16.7661 18.1353Z"
					fill="black"
				></path>
			</svg>
	);
}
