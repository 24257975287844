import React from "react";
import ReactDOM from "react-dom";
import "./Assets/Css/index.css";
import "./Assets/Css/App.css";
import "./Fonts/NocturnSerif/NocturneSerif-Regular.ttf";
import "./Fonts/Adieu/Adieu-Regular.woff";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
 