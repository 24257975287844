export const GET_PRODUCTS = ` 
query     
{
  allLalouProducts(orderBy: price_ASC) {
    price,
    id,
 _createdAt,
 _status,
 createdAt,
 shopifyVariantId,
 oneTimeVariantId,
 shopifyVariantIdOnetime,
 shopifyVariantIdThreeGift,
 productTitle,
 productSku,
 active,
 subtitle, 
 featuredImage {
    url,
   alt,
   width,
   height,
   format,
   alt,
   blurUpThumb,
   blurhash,
   filename
 },
 productImages {
   url,
   alt,
   width,
   height,
   format,
   alt,
   blurUpThumb,
   blurhash,
   filename
 },
 productDescription,
 secondTitle,
 productId,
 sortOrder,
 shopifygraphqlid
  }
}`;

export const GET_HERO = `
query {
  lalouHero {
    header
    paragraph
    productSectionHeader
  }
}`;

export const GET_ABOUT = `
query {
  lalouAbout{
   id
   header
   paragraph
   image1 {
        url,
        alt,
        width,
        height,
        format,
        alt,
        blurUpThumb,
        blurhash,
        filename
   }
   image2 {
        url,
        alt,
        width,
        height,
        format,
        alt,
        blurUpThumb,
        blurhash,
        filename
   }
   image3 {
        url,
        alt,
        width,
        height,
        format,
        alt,
        blurUpThumb,
        blurhash,
        filename
   }
   image4 {
        url,
        alt,
        width,
        height,
        format,
        alt,
        blurUpThumb,
        blurhash,
        filename
   }
  }
}`;
