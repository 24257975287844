import { queryDatoCms, queryParcelleDb, queryShopify } from "./config";
import { GET_ABOUT, GET_HERO, GET_PRODUCTS } from "./Queries/products.query";

export async function getProducts() {
  const answer = await queryDatoCms(GET_PRODUCTS)
    .then((result) => {
      return result?.data?.data?.allLalouProducts;
    })
    .catch(() => {
      return false;
    });
  return answer;
}

export async function getHeroData() {
  const answer = await queryDatoCms(GET_HERO)
    .then((result) => {
      // console.log(result);
      return result?.data?.data?.lalouHero;
    })
    .catch(() => {
      return false;
    });
  return answer;
}

export async function getLalouProducts() {
  const answer = await queryShopify("w_l_parcelle/getLalouProducts")
    .then((result) => {
      return result?.data?.data?.products;
    })
    .catch(() => {
      return false;
    });
  return answer;
}


// get laloutAbout
export async function getAboutData() {
  const answer = await queryDatoCms(GET_ABOUT)
    .then((result) => {
      return result?.data?.data?.lalouAbout;
    })
    .catch(() => {
      return false;
    });
  return answer;
}
