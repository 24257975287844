
import React from 'react'

function Remove() {
    return (
        <div >
            <svg className='remove-icon' width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.331 2.66892C8.93597 0.273861 5.06403 0.273861 2.66897 2.66892C0.273912 5.06398 0.273912 8.93592 2.66897 11.331C5.06403 13.726 8.93597 13.726 11.331 11.331C13.7261 8.93592 13.7261 5.06398 11.331 2.66892ZM3.53518 10.4648C1.62519 8.55479 1.62519 5.44511 3.53518 3.53513C5.44516 1.62514 8.55484 1.62514 10.4648 3.53513C12.3748 5.44511 12.3748 8.55479 10.4648 10.4648C8.55484 12.3748 5.44516 12.3748 3.53518 10.4648ZM4.7522 6.38914H9.24781C9.57697 6.38914 9.85415 6.66633 9.85849 6.99982C9.85849 7.33764 9.58563 7.61049 9.24781 7.61049H4.7522C4.41438 7.61049 4.14153 7.33764 4.14153 6.99982C4.14153 6.662 4.41438 6.38914 4.7522 6.38914Z" fill="black" fillOpacity="0.58"/>
            </svg>
            
        </div>
    )
}

export default Remove

