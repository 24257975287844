import React, { useState } from "react";
import CrossIcon from "../Icons/Cross";
import SlideoutProduct from "./SlideoutProduct";

const Slideout = ({
  openCart,
  setOpenCart,
  showDialog,
  closeDialog,
  cart,
  setCart,
  handleCheckout,
  loading,
}) => {
  const myCartClass = openCart
    ? "slideout-wrapper active-slide"
    : "slideout-wrapper";

  let removedCartDuplicates = cart; // removeDuplicates(cart, "id");

  // helper method that adds up price of each product
  const sumPrice = (accumulator, currentValue) => accumulator + currentValue;

  // method to add up total price of all products in cart
  const productTotal = () => {
    return productPrices?.reduce(sumPrice);
  };
  const countOfProducts = () => {
    return productCounts?.reduce(sumPrice);
  };

  function getShipmentPrice() {
    let shipmentPrice = 0;

    // replace the above function with .some
    if (
      cart.some(
        (lili) => lili?.id !== "44318613078258" && lili?.id !== "44318631657714"
      )
    ) {
      shipmentPrice += 20;
    }

    if (
      cart.some(
        (lili) => lili?.id === "44318613078258" || lili?.id === "44318631657714"
      )
    ) {
      shipmentPrice += 60;
    }

    return shipmentPrice;
  }

  //TOTAL PRICE OF PRODUCTS
  const productPrices = cart?.map((product) => {
    console.log(product, "product");
    return product?.price * product.quantity;
  });

  const productCounts = cart?.map((product) => product.quantity);
  return (
    <div>
      <div className={myCartClass}>
        <div className="slideout-header-wrapper">
          <h2
            className="slideout-h2"
            style={{
              paddingLeft: "24px",
              paddingTop: "24px",
              fontSize: "14px",
              textAlign: "left",
              top: "-11px",
            }}>
            Shopping Bag
          </h2>
          <div className="slideout-header-x-wrapper">
            <CrossIcon
              clickFunc={() => {
                setOpenCart(!openCart);
              }}
            />
          </div>
        </div>

        {cart.length > 0 && (
          <>
            <div className="slideout-products-wrapper">
              {removedCartDuplicates.map((product) => {
                return (
                  <SlideoutProduct
                    key={
                      product.id +
                      "-" +
                      product.secondTitle +
                      product.purchaseType
                    }
                    {...{
                      openCart,
                      setOpenCart,
                      showDialog,
                      closeDialog,
                      setCart,
                      cart,
                    }}
                    product={product}
                    amount={product.quantity}
                  />
                );
              })}
            </div>

            <div className="slideout-bottom-wrapper">
              <div className="slideout-bottom-section first-bottom">
                <h3 className="slidder-shipping">Shipping </h3>
                <h3 className="slidder-shipping">${getShipmentPrice()}</h3>
              </div>
              <div className="slideout-bottom-section second-bottom">
                <h3 className="subtotal-h3">Subtotal</h3>
                <h3 className="subtotal-cost">
                  <span className="dollar-sign">$</span>{" "}
                  {productTotal() + getShipmentPrice()}
                </h3>
              </div>
              <button
                onClick={handleCheckout}
                className="section-2-bottom-header-button checkout-button"
                style={{ marginTop: "0.9rem" }}>
                {loading ? (
                  <div className="loader">Loading...</div>
                ) : (
                  "Checkout"
                )}
              </button>
              <p
                className="checkout-continue-shopping"
                onClick={() => {
                  setOpenCart(!openCart);
                }}>
                Continue shopping
              </p>
            </div>
          </>
        )}
        {!cart.length && <div className="empty-cart">Your cart is empty</div>}
      </div>
    </div>
  );
};

export default Slideout;
