export default function CrossIcon({ clickFunc }) {
  return (
    <svg
      onClick={clickFunc}
      className="slideout-header-x h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      style={{ cursor: "pointer" }}
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
      <div style={{ opacity: 0, display: "none" }}>Close Icon</div>
    </svg>
  );
}
